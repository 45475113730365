<template>
  <div>
    <!-- <v-form @submit.prevent> -->
    <h3 :class="$vuetify.breakpoint.lg ? 'ml-15 mt-5' : 'ml-6 mt-2'">
      Basic Settings
    </h3>
    <v-card style="width: 80%; margin: auto; margin-top: 30px" elevation="0">
      <!-- Basic settings -->
      <v-row :class="$vuetify.breakpoint.lg ? '' : 'flex-column-reverse'">
        <v-col :cols="$vuetify.breakpoint.lg ? 8 : 12">
          <v-row
            v-for="(info, index) in companyInfo"
            :key="index"
            :class="info.type == 'switch' ? 'align-center' : 'align-baseline'"
          >
            <v-col :cols="$vuetify.breakpoint.lg ? 3 : 12" class="pr-0">
              <p class="fw-600 fs-14 f-inter mb-0">
                {{ info.name }}
                <v-tooltip
                  v-if="info.tooltipCheck"
                  top
                  color="numberField"
                  max-width="200px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="lightPurple"
                      style="padding-bottom: 3px"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span class="black--text fs-10 fw-400">{{
                    info.tooltipText
                  }}</span>
                </v-tooltip>
              </p>
            </v-col>
            <v-col
              class="pt-0 pb-0 mt-6"
              :cols="$vuetify.breakpoint.lg ? 9 : 12"
            >
              <v-text-field
                v-if="info.type == 'text'"
                class="fs-12 fw-400"
                outlined
                dense
                :placeholder="info.placeholder"
                v-model="info.value"
                :disabled="info.disabled"
                hide-details
              />
              <v-select
                v-if="info.type == 'dropdown'"
                hide-details
                dense
                v-model="status"
                :items="submissionOptions"
                class="submission-dropdown rounded-lg pt-1 pl-5 pr-3 fs-12 fw-600 f-inter"
              >
              </v-select>
              <v-btn
                v-if="info.type == 'switch'"
                class="pb-6 d-flex justify-center mb-0 mt-0"
                icon
                :color="approvalCheck ? 'primary' : 'inActiveColor'"
                :ripple="false"
                @click="rewriteInvoice"
              >
                <v-icon size="28" :ripple="false">{{
                  approvalCheck ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
                }}</v-icon>
              </v-btn>

              <p v-if="info.name == 'Email'" class="fs-13 fw-600 mb-0 f-inter">
                {{ companyInfo[2].value + "@" + smtp }}
                <v-icon size="medium" color="lightPurple" @click="copyLink()"
                  >mdi-content-copy</v-icon
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
        <!-- Upload logo -->
        <v-col
          :cols="$vuetify.breakpoint.lg ? 4 : 12"
          class="justify-center pt-0"
        >
          <div class="d-flex flex-column align-center">
            <v-avatar
              class="profile mb-3"
              size="100"
              rounded
              style="border: 1px dotted #f5f5f5"
              @click="chooseFiles()"
            >
              <input
                ref="fileInput"
                id="fileUpload"
                type="file"
                accept=" image/jpeg, image/jpg, image/png"
                hidden
                @change="uploadFile($event)"
              />
              <!-- lazy-src="../../../assets/svg-icons/file-upload-latest.svg" -->
              <v-img
                v-if="currentWorkspace.workspaceProfilePicture"
                :src="
                  currentWorkspace.workspaceProfilePicture
                    ? `${backendURL}slack/${token}?file=${currentWorkspace.workspaceProfilePicture}`
                    : '@/assets/placeholder-image.png'
                "
                class="logo"
                width="100"
                height="100"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fit-height mt-7"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-else
                src="../../../assets/svg-icons/file-upload-latest.svg"
              ></v-img>
            </v-avatar>
            <v-btn
              class="fs-12 mb-3 fw-600 lightPurple--text text-none elevation-0 logo-button"
              :loading="removeLogoLoader"
              :disabled="removeLogoBtn"
              @click="chooseFiles('remove')"
            >
              Remove Logo</v-btn
            >
            <v-btn
              class="fs-12 fw-600 lightPurple--text text-none elevation-0 logo-button"
              :loading="changeLogoLoader"
              @click="chooseFiles()"
            >
              Change Logo</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>

    <h3 :class="$vuetify.breakpoint.lg ? 'ml-15 mt-15' : 'ml-6 mt-8'">
      Contact Details
    </h3>
    <v-card style="width: 80%; margin: auto; margin-top: 30px" elevation="0">
      <!-- Contact Details -->
      <v-row
        v-for="(contact, index) in contactInfo"
        :key="index"
        align="baseline"
      >
        <v-col
          :class="$vuetify.breakpoint.lg ? 'pb-0' : 'pt-0 pb-0'"
          :cols="$vuetify.breakpoint.lg ? 2 : 12"
        >
          <p class="fw-600 fs-14 f-inter mb-0">{{ contact.name }}</p>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.lg ? 5 : 12">
          <v-text-field
            v-if="contact.type == 'text'"
            class="fs-12 fw-400"
            outlined
            dense
            :placeholder="contact.placeholder"
            hide-details
            v-model="contact.value"
          />
          <v-textarea
            v-if="contact.type == 'textArea'"
            :placeholder="contact.placeholder"
            hide-details
            v-model="contact.value"
            class="fs-12 fw-400"
            outlined
            dense
            height="80px"
            no-resize
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7"></v-col>
        <v-col cols="5" class="px-0">
          <div
            :class="
              $vuetify.breakpoint.lg
                ? 'd-flex justify-start'
                : 'd-flex justify-end'
            "
          >
            <v-btn
              class="fs-12 fw-600 lightPurple--text rounded-lg px-6 elevation-0"
              :loading="loader"
              @click="updateWorkspaceDetails()"
            >
              Save</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import rules from "../../../constants/validation-rules.js";
import apiService from "../../../services/Api";
import UrlServices from "../../../services/Url";

export default {
  data() {
    return {
      token: null,
      removeLogoBtn: true,
      removeLogoLoader: false,
      changeLogoLoader: false,
      loader: false,
      backendURL: process.env.VUE_APP_BACK_END_URL,
      fileInput: null,
      approvalCheck: false,
      selectedSubmission: "",
      smtp: process.env.VUE_APP_SMTP_MAIL,
      status: "",
      submissionOptions: ["DRAFT", "AUTHORISED", "SUBMITTED"],
      currencyRule: [rules.select_field_required],
      contactInfo: [
        {
          name: "Business email",
          value: "",
          placeholder: "Business Email",
          type: "text",
          key: "businessEmail",
        },
        {
          name: "Address",
          value: "",
          placeholder: "Address",
          type: "textArea",
          key: "address",
        },
        {
          name: "",
          value: "",
          placeholder: "City",
          type: "text",
          key: "city",
        },
        {
          name: "",
          value: "",
          placeholder: "State/Province",
          type: "text",
          key: "state",
        },
        {
          name: "",
          value: "",
          placeholder: "Country",
          type: "text",
          key: "country",
        },
        {
          name: "Website",
          value: "",
          placeholder: "Business website",
          type: "text",
          key: "website",
        },
      ],
      companyInfo: [
        {
          name: "Name",
          value: "",
          placeholder: "Organization Name",
          type: "text",
          key: "workspaceName",
          tooltipCheck: false,
        },
        {
          name: "Legal Name",
          value: "",
          placeholder: "Legal Business Name",
          type: "text",
          key: "legalBusinessName",
          tooltipCheck: false,
        },
        {
          name: "Email",
          value: "",
          key: "email",
          tooltipCheck: true,
          tooltipText:
            "Use this email to directly receive the documents from your vendor/ client",
        },
        {
          name: "Industry",
          value: "",
          placeholder: "Line of Business",
          type: "text",
          key: "industry",
          tooltipCheck: false,
        },
        {
          name: "Default Submission",
          value: "",
          type: "dropdown",
          key: "defaultSubmissionStatus",
          tooltipCheck: true,
          tooltipText:
            "The document will be posted in the selected status in your connected accounting software",
        },
        {
          name: "Auto Approval",
          value: "",
          type: "switch",
          key: "rewriteInvoice",
          tooltipCheck: true,
          tooltipText:
            "Turn this on to allow system to automatically record transaction in the connected accounting software",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  mounted() {
    if (localStorage.getItem("token"))
      this.token = localStorage.getItem("token");
    this.fetchWorkspaceDetails();
    if (this.currentWorkspace.workspaceProfilePicture) {
      this.removeLogoBtn = false;
    }
  },

  methods: {
    ...mapActions({
      updateWorkspace: "auth/updateWorkspace",
    }),

    fetchWorkspaceDetails() {
      this.companyInfo.forEach((info) => {
        for (const [key, value] of Object.entries(this.currentWorkspace)) {
          info.key == key && (info.value = value);
          key == "rewriteInvoice" && (this.approvalCheck = value);
          key == "defaultSubmissionStatus" && (this.status = value);
        }
      });

      this.contactInfo.forEach((contact) => {
        for (const [key, value] of Object.entries(
          this.currentWorkspace?.contactDetails
        )) {
          contact.key == key && (contact.value = value);
        }
      });
    },
    updateWorkspaceDetails() {
      this.loader = true;
      const contactDetailsPayload = {
        businessEmail: this.contactInfo[0].value,
        address: this.contactInfo[1].value,
        city: this.contactInfo[2].value,
        state: this.contactInfo[3].value,
        country: this.contactInfo[4].value,
        website: this.contactInfo[5].value,
      };
      let payload = {
        workspaceName: this.companyInfo[0].value.trim(),
        legalBusinessName: this.companyInfo[1].value,
        industry: this.companyInfo[3].value,
        defaultSubmissionStatus: this.status,
        contactDetails: contactDetailsPayload,
      };

      this.updateWorkspace(payload)
        .then((res) => {
          console.log(res.data, "data");
          this.loader = false;
          this.editDialog = false;
          this.$showNotificationBar("success", "Updated Successfully");
          this.$emit("fetch-personal-info");
        })
        .catch((error) => {
          this.loader = false;
          this.$showNotificationBar(
            "error",
            error?.message ? error.message : "Error Occurred"
          );
        });
    },

    /**
     * Method to enable/disable option to rewrite invoice
     */
    rewriteInvoice() {
      this.approvalCheck = !this.approvalCheck;
      apiService
        .PostRequest(
          UrlServices.invoiceEdit + "/" + this.currentWorkspace._id,
          {
            rewriteInvoice: this.approvalCheck,
          }
        )
        .then((res) => {
          const enabled = res.data.workspace.rewriteInvoice;
          this.$store.commit("auth/setWorkspaceEditInvoice", {
            key: "rewriteInvoice",
            value: enabled,
          });
          this.$showNotificationBar(
            "success",
            `Permission to edit invoice has been ${
              enabled ? "disabled" : "enabled"
            } successfully`
          );
        })
        .catch((e) => {
          console.log("err>>", e);
        });
    },

    // Copy the text inside the text field
    copyLink() {
      var copyText = `${this.currentWorkspace.email}@${this.smtp}`;
      navigator.clipboard.writeText(copyText);
      this.$showNotificationBar("success", "Email copied");
    },
    /**
     * Method to set profile picture of the workspace
     */
    uploadFile(event) {
      const file = event == "remove" ? "" : event.target.files[0];
      if (file?.size >= 5000000) {
        this.$showNotificationBar(
          "error",
          "File Size should be less than 5 mb."
        );
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      apiService
        .PostRequest(
          `${UrlServices.updateProfilePicture}/${this.currentWorkspace.workspaceId}`,
          formData
        )
        .then((res) => {
          console.log(res.data.data, "res.data.data");
          this.$store.commit("auth/addCurrentWorkspace", res.data.data);
          this.$showNotificationBar(
            "success",
            `Profile picture of workspace updated successfully`
          );
          this.removeLogoBtn = event == "remove" ? true : false;
        })
        .catch((e) => {
          console.log("err>>", e);
        });
    },
    // Method to choose file
    chooseFiles(val) {
      if (val == "remove") {
        this.uploadFile("remove");
      } else {
        document.getElementById("fileUpload").click();
      }
    },
  },
};
</script>

<style scoped>
:deep .v-chip .v-chip__content {
  color: var(--v-lightPurple-base) !important;
  background-color: var(--v-lightPurpleBg-base) !important;
  padding: 5px 10px;
}
.submission-dropdown {
  width: 150px;
  height: 35px;
  background-color: var(--v-lightPurpleBg-base) !important;
}
:deep .v-select.v-input--dense .v-select__selection--comma {
  color: var(--v-lightPurple-base) !important;
}
.logo-button {
  height: 35px !important;
  background: #f5f3fb !important;
  border-radius: 8px !important;
}
:deep .logo .v-image__image--cover {
  background-size: contain !important;
}
.v-btn::before {
  background-color: transparent !important;
}
</style>
